<template>
  <div>
    <HeadCard />
    <a-card title="邮件支持" class="context">
      <div class="support">
        <h3>如有问题，请发送邮件</h3>
        <a-button type="primary">
          <a href="mailto:adelinevilchistcf80@gmail.com" target="_blank">Email联系我们</a>
        </a-button>
      </div>
    </a-card>
    <a-card title="Telegram支持" class="context">
      <div class="support">
        <h3>在线随时支持</h3>
        <a-button type="primary">
          <a href="https://t.me/dufei888" target="_blank">TG联系我们</a>
        </a-button>
      </div>
    </a-card>
    <Footer style="margin-top: 400px" />
  </div>
</template>

<script>
import HeadCard from "../../components/HeadCard";
import Footer from "../../components/Footer";

export default {
  components: {
    HeadCard,
    Footer,
  },
};
</script>

<style scoped lang="scss">
.context {
  width: 65%;
  margin: 20px auto;
}
.support {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 20px;
}
</style>
